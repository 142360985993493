import './offers-view.scss';
import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { MooringCreateOfferDialog } from 'features/mooring/offers/mooring-create-offer-dialog';
import { AsyncErrorHandler, ViewLoadingFader } from 'lib/ui';
import { MooringOfferListItemDto, MooringOfferStatusTranslation } from 'models/mooring';
import { MooringOfferService } from 'services/mooring';
import { PubSub } from 'lib/event/PubSub';
import { FilterManager } from 'lib/tables/FilterManager';
import { OfferView } from './offer-view';
import { Filters } from 'elements/Filter';
import { I18N } from 'aurelia-i18n';
import { CustomerService } from 'services/customer-service';

@autoinject
export class OffersView {
  protected offers: MooringOfferListItemDto[] = [];

  constructor(
    protected element: Element,
    protected fm: FilterManager,
    private moooringOfferService: MooringOfferService,
    private dialogService: DialogService,
    private customerService: CustomerService,
    private t: I18N,
    private pubsub: PubSub
  ) {
    pubsub.sub('filter-manager:filter-changed', () => {
      this.getAllOffers();
    });
  }

  @AsyncErrorHandler
  async getCustomers() {
    const customres = await this.customerService.getAll();
    return customres.filter((x) => !x.IsDeleted);
  }

  @ViewLoadingFader
  async activate() {
    const customers = await this.getCustomers();

    this.fm.init({
      context: OfferView.name,
      storageKey: 'mooring-offers',
      filters: {
        customerIds: {
          type: 'checkbox',
          label: this.t.tr('general.customer'),
          name: 'customerIds',
          query: 'customerIds',
          options: customers.map((x) => ({
            Name: x.Name,
            Id: x.Id,
          })),
          disabled: false,
        },
        statusIds: {
          type: 'checkbox',
          label: 'Status',
          name: 'statusIds',
          query: 'statusIds',
          options: Object.entries(MooringOfferStatusTranslation).map(([id, translation]) => ({
            Name: this.t.tr<string>(translation),
            Id: id,
          })),
          disabled: false,
        },
      } satisfies Filters,
      defaultFilters: {
        statusIds: true,
        customerIds: true,
      },
    });

    await this.getAllOffers();
  }

  @AsyncErrorHandler
  async getAllOffers() {
    const { data, headers } = await this.moooringOfferService.getOffers(this.fm.queryFilter);
    const record = headers.get('x-total-count');
    this.fm.paginationTotalRecords = record ? +record : 0;
    this.offers = data;
  }

  protected openCreateOfferDialog() {
    void this.dialogService.open({
      viewModel: MooringCreateOfferDialog,
    });
  }

  protected detached() {
    this.pubsub.unsub();
  }
}
