import './overview.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { MooringService } from 'services/mooring';
import { renderMap } from 'views/mooring/mooring-map';

@autoinject()
export class Overview {
  @bindable
  offerId: number;

  @bindable
  mooringId: number;

  /**
   *
   */
  constructor(private mooringService: MooringService) {}

  attached() {
    void this.renderMap();
  }

  protected async renderMap() {
    const { data } = await this.mooringService.getMooringDrawingSetup(this.mooringId);
    renderMap(data);
  }
}
