import { autoinject, bindable } from 'aurelia-framework';
import { TableManager } from './TableManager';

@autoinject()
export class ColumnHandler {
  @bindable()
  protected manager: TableManager;

  protected attached() {}
}
