import { DialogService } from 'aurelia-dialog';
import { bindable } from 'aurelia-framework';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import {
  ComponentTemplateType,
  CreateTemplateDialogArgs,
  MooringCreateUpdateTemplateDialog,
} from 'features/mooring/component-templates/mooring-create-template-dialog';
import {
  MooringSelectEntriesForPupulateArgs,
  MooringSelectEntriesForPupulateDialog,
  MooringSelectEntriesForPupulateResult,
} from 'features/mooring/component-templates/mooring-select-entries-for-populate';
import {
  MooringSelectTemplateDialog,
  SelectTemplateDialogArgs,
  SelectTemplateDialogResult,
} from 'features/mooring/component-templates/mooring-select-template-dialog';
import { MooringArticleFinderDialog, MooringArticleFinderDialogResult } from 'features/mooring/mooring-article-finder-dialog';
import { MooringComponentCommentDialog, MooringComponentCommentDialogArgs } from 'features/mooring/mooring-component-comment-dialog';
import { PubSub } from 'lib/event/PubSub';
import { EqualityChecker } from 'lib/helpers';
import { AsyncErrorHandler } from 'lib/ui';
import { InputType } from 'models/ListSettings';
import {
  MooringArticle,
  MooringArticleSearchResult,
  MooringTemplateDto,
  MooringComponent,
  MooringElementsListDto,
  MooringComponentType,
  MooringComponentTemplateEntryDto,
} from 'models/mooring';
import { MooringArticleService } from 'services';
import { MooringComponentSearchHandler, MooringService } from 'services/mooring';
import { TableEntryManager } from 'services/ui-helpers';
import { SearchResult } from 'views/mooring/mooring-salesorder/search-box';

export class MooringTableBase<T extends { Id: number; MoorlogNo: string; Components: MooringComponent[] }> {
  @bindable
  protected mooringId: number;

  @bindable
  protected entries: T[];
  entriesChanged(newValue: T[]) {
    if (!newValue?.length) return;
    this.changeTracker = new EqualityChecker();
    this.changeTracker.track(newValue);
  }

  protected mooringElements: MooringElementsListDto;

  protected changeTracker = new EqualityChecker();

  protected articles: MooringArticle[];

  protected editRows = false;

  protected openBoxes: { [key: string]: boolean } = {};

  protected loadUnitTypeOnPaste: 'tons' | 'kg' = 'tons';

  protected get defaultComponent() {
    return {
      Id: 0,
      UtilizationFactor: 0,
      HasLoadConstraint: true,
      ArticleId: 0,
      Quantity: 1,
      TypeName: '',
      CategoryName: '',
      ProductName: '',
      SortIndex: 0,
    };
  }

  /**
   *
   */
  constructor(
    protected type: ComponentTemplateType,
    protected dialogService: DialogService,
    protected mooringComponentSearchHandler: MooringComponentSearchHandler,
    protected tableEntryManager: TableEntryManager,
    protected mooringArticleService: MooringArticleService,
    protected pubsub: PubSub,
    protected confirmation: ConfirmDialog,
    protected mooringService: MooringService
  ) {}

  _tableScrollHandler: () => void;
  _tableResizeHandler: () => void;

  private attachStickyHeaderHandler() {
    const fixedHeader = document.getElementById('table-fixed-header');
    const tableHeader = document.getElementById('table-main-header');

    // Match the width of the table header to the fixed header
    this._tableResizeHandler = () => {
      const tableCells = tableHeader.querySelectorAll('th');
      const fixedCells = fixedHeader.querySelectorAll('th');

      fixedCells.forEach((cell, index) => {
        cell.style.width = `${tableCells[index].offsetWidth}px`;
      });
    };

    this._tableScrollHandler = () => {
      const rect = tableHeader.getBoundingClientRect();
      // Check if the table header is out of view
      if (rect.top <= 0) {
        fixedHeader.classList.remove('hidden');
        fixedHeader.style.width = `${rect.width}px`;
        this._tableResizeHandler();
      } else {
        fixedHeader.classList.add('hidden');
      }
    };

    window.addEventListener('scroll', this._tableScrollHandler);
    window.addEventListener('resize', this._tableResizeHandler);
  }

  async attached() {
    await this.getMooringElements();
    this.articles = await this.mooringArticleService.getAll('$expand=ArticleProducts($expand=Product)');
    this.attachStickyHeaderHandler();
  }

  public async searchForElement(type: MooringComponentType, term: string) {
    if (type === 'buoy') {
      return this.mooringElements.Buoys.filter((x) => x.Name.toLowerCase().includes(term)).map((x) => ({
        id: x.Id,
        title: x.Name,
      }));
    }
    if (type === 'cage') {
      return this.mooringElements.Cages.filter((x) => x.Name.toLowerCase().includes(term)).map((x) => ({
        id: x.Id,
        title: x.Name,
      }));
    }
    return [];
  }

  @AsyncErrorHandler
  public async getElementDetails(type: MooringComponentType, id: number) {
    if (type === 'buoy') {
      const b = this.mooringElements.Buoys.find((x) => x.Id === id);
      return {
        id,
        title: b.Name,
      };
    }

    if (type === 'cage') {
      const b = this.mooringElements.Cages.find((x) => x.Id === id);
      return {
        id,
        title: b.Name,
      };
    }
  }

  protected onArticleSelected(searchResult: SearchResult, component: MooringComponent) {
    const res = searchResult.return as MooringArticleSearchResult;
    component.ArticleId = res.Id;
    component.MBL = res.MBL;
    component.CategoryName = res.Category;
    component.TypeName = res.Type;
    component.ProductTypeName = res.ProductType;

    this.pubsub.publish('list-entity:updated', { name: 'mooring-article-component' });
  }

  @AsyncErrorHandler
  protected async getMooringElements() {
    const { data } = await this.mooringService.getMooringElementsList(this.mooringId);
    this.mooringElements = data;
  }

  protected addComponent(lineId: number, index?: number) {
    const line = this.entries.find((x) => x.Id === lineId);
    if (!line) return;
    if (!line.Components) line.Components = [];
    line.Components = this.tableEntryManager.addEntry(line.Components, this.defaultComponent, index);
  }

  protected removeComponent(lineId: number, index: number) {
    const line = this.entries.find((x) => x.Id === lineId);
    if (!line) return;
    line.Components = this.tableEntryManager.removeEntry(line.Components, index);
  }

  protected moveComponentUp(lineId: number, index: number) {
    const line = this.entries.find((x) => x.Id === lineId);
    if (!line) return;
    line.Components = this.tableEntryManager.moveEntryUp(line.Components, index);
  }

  protected moveComponentDown(lineId: number, index: number) {
    const line = this.entries.find((x) => x.Id === lineId);
    if (!line) return;
    line.Components = this.tableEntryManager.moveEntryDown(line.Components, index);
  }

  protected setEdit() {
    this.editRows = !this.editRows;
  }

  protected openSubTable(id: string | number) {
    this.openBoxes[id] = !this.openBoxes[id];
    this.openBoxes = { ...this.openBoxes };
  }

  protected contractAllRows() {
    this.openBoxes = {};
  }

  protected expandAllRows() {
    this.entries.forEach((line) => {
      this.openBoxes[line.Id] = true;
    });
  }

  kgAdjust = [
    'MinMblRopesStraps',
    'MinMblChainShackleConnectionPlates',
    'MinMblAnchoring',
    'DimForce',
    'BottomAnchoringUplift',
    'HoldingPowerAnchoring',
    'PullDownKg',
    'ResidualDriftKg',
  ];

  protected postPasteAdjustment(_: number, __: string) {
    //
  }

  protected pasteValue(event: ClipboardEvent, field: string, index: number, _?: InputType) {
    if (!this.entries?.length) return;

    const data = event.clipboardData?.getData('text');
    if (!data) return;
    const lines = data.split('\n').filter(Boolean);

    for (let i = 0; i < lines.length; i++) {
      const entryIndex = index + i;
      if (entryIndex >= this.entries.length) break;
      const entry = this.entries[entryIndex];
      if (this.kgAdjust.includes(field)) {
        const value = lines[i]?.trim().replace(',', '.').replaceAll(' ', '').replace(/\D\./g, '');
        let numericValue = +value;
        // All load values should be in KG's
        if (this.loadUnitTypeOnPaste === 'tons') {
          numericValue *= 1000;
        }

        if (Number.isNaN(numericValue)) {
          numericValue = 0;
        }

        entry[field] = numericValue;
      } else {
        entry[field] = lines[i]?.trim();
      }

      this.postPasteAdjustment(entryIndex, field);
    }
  }

  protected saveAsTemplate(lineId: number) {
    const line = this.entries.find((x) => x.Id == lineId);
    if (!line) return;

    this.dialogService.open({
      viewModel: MooringCreateUpdateTemplateDialog,
      position: () => 0,
      model: {
        action: 'create',
        type: this.type,
        components: line.Components.map((x) => ({
          SortIndex: x.SortIndex,
          ArticleId: x.ArticleId,
          ProductName: x.ProductName,
          HasLoadConstraint: x.HasLoadConstraint,
          MBL: x.MBL,
          CategoryName: x.CategoryName,
          TypeName: x.TypeName,
          ProductTypeName: x.ProductTypeName,
        })),
      } satisfies CreateTemplateDialogArgs,
    });
  }

  /**
   * Populate line with components.
   * If a line id is provided, only that line will be populated,
   * else all will be.
   */
  protected populate(components: MooringComponentTemplateEntryDto[], lineId?: number) {
    const mooringComponents: MooringComponent[] = components
      .map((x) => ({
        Id: 0,
        ArticleId: x.ArticleId,
        TypeName: x.TypeName,
        Quantity: x.Quantity || 1,
        MBL: x.MBL,
        WLL: x.WLL,
        SortIndex: x.SortIndex,
        ProductName: x.ProductName,
        CategoryName: x.CategoryName,
        HasLoadConstraint: x.HasLoadConstraint,
        ProductTypeName: x.ProductTypeName,
        UtilizationFactor: 0,
      }))
      .sort((a, b) => a.SortIndex - b.SortIndex);

    if (!lineId) {
      for (const line of this.entries) {
        if (!line.Components) {
          line.Components = [];
        }
        line.Components.push(...structuredClone(mooringComponents));
        this.tableEntryManager.organizeSortIndex(line.Components);
      }
    } else {
      const line = this.entries.find((x) => x.Id == lineId);
      line.Components?.push(...mooringComponents);
      this.tableEntryManager.organizeSortIndex(line.Components);
    }
  }

  protected selectArticleToPopulateLines() {
    this.dialogService
      .open({
        viewModel: MooringArticleFinderDialog,
        position: () => 0,
      })
      .whenClosed(({ output }: { output?: MooringArticleFinderDialogResult }) => {
        if (!output?.component) return;
        const component = output.component;

        this.dialogService
          .open({
            viewModel: MooringSelectEntriesForPupulateDialog,
            position: () => 0,
            model: {
              options: this.entries.map((x) => ({ name: x.MoorlogNo, id: x.Id })),
            } satisfies MooringSelectEntriesForPupulateArgs,
          })
          .whenClosed(({ output }: { output?: MooringSelectEntriesForPupulateResult }) => {
            if (!output) return;

            for (const selection of output.selections) {
              if (selection.selected) {
                this.populate([component], selection.id);
              }
            }
          });
      });
  }

  protected selecteTemplateToPopulateLines(lineId?: number) {
    this.dialogService
      .open({
        viewModel: MooringSelectTemplateDialog,
        position: () => 0,
        model: {
          type: this.type,
        } satisfies SelectTemplateDialogArgs,
      })
      .whenClosed(({ output }: { output?: SelectTemplateDialogResult }) => {
        if (!output) return;
        const template = output.template;

        if (!lineId) {
          this.dialogService
            .open({
              viewModel: MooringSelectEntriesForPupulateDialog,
              position: () => 0,
              model: {
                options: this.entries.map((x) => ({ name: x.MoorlogNo, id: x.Id })),
              } satisfies MooringSelectEntriesForPupulateArgs,
            })
            .whenClosed(({ output }: { output?: MooringSelectEntriesForPupulateResult }) => {
              if (!output) return;

              for (const selection of output.selections) {
                if (selection.selected) {
                  this.populate(template.Components ?? [], selection.id);
                }
              }
            });
        } else {
          this.populate(template.Components ?? [], lineId);
        }
      });
  }

  protected duplicate(entryIndex: number, componentIndex: number) {
    const entry = this.entries[entryIndex];
    if (!entry) return;

    const original = entry.Components[componentIndex];
    const newEntry = new MooringComponent();
    Object.assign(newEntry, original);
    newEntry.Id = undefined;

    entry.Components = this.tableEntryManager.addEntry(entry.Components, newEntry, componentIndex);
  }

  protected scrollToTop() {
    document.querySelector('.app__header')?.scrollIntoView({ behavior: 'smooth' });
  }

  protected openCommentDialog(model: MooringComponentCommentDialogArgs) {
    this.dialogService
      .open({
        viewModel: MooringComponentCommentDialog,
        position: () => 0,
        model,
      })
      .whenClosed(() => {
        this.pubsub.publish('list-entity:updated', null);
      });
  }

  protected unbind() {
    window.removeEventListener('scroll', this._tableScrollHandler);
    window.removeEventListener('resize', this._tableResizeHandler);
  }
}
