import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { PubSub } from 'lib/event/PubSub';
import { AsyncErrorHandler, ComponentLoadingFader } from 'lib/ui';
import { MooringComponentType, MooringGridLine, UpdateMooringGridLineDto } from 'models/mooring';
import { ToastService } from 'services';
import { MooringService } from 'services/mooring';

@autoinject
export class GridLinesContainer {
  @bindable
  protected mooringId: number;

  @bindable
  protected reviewId?: number;

  protected gridLines: MooringGridLine[] = [];

  constructor(
    protected element: Element,
    private mooringService: MooringService,
    private toaster: ToastService,
    private t: I18N,
    private pubsub: PubSub
  ) {
    pubsub.sub('entity:updated', async () => {
      void this.getGridLines();
    });
    pubsub.sub('list-entity:updated', async () => {
      void this.getGridLines();
    });
  }

  @AsyncErrorHandler
  @ComponentLoadingFader
  async attached() {
    if (!this.mooringId) {
      throw new Error('mooringId is required => mooring-id.bind="<id>"');
    }

    await this.getGridLines();
  }

  @AsyncErrorHandler
  protected async getGridLines() {
    const { data } = await this.mooringService.getGridLines(this.mooringId);
    const lines = data;
    if (lines.length) {
      for (const line of lines) {
        line.Components?.sort((a, b) => a.SortIndex - b.SortIndex);
      }
      setTimeout(() => {
        this.gridLines = lines;
      }, 10);
    }
  }

  @AsyncErrorHandler
  protected async onSaveGridLines({ entries, canNavigateOnSave }: { entries: MooringGridLine[]; canNavigateOnSave: boolean }) {
    const dto = entries.map(
      (line) =>
        ({
          Id: line.Id,
          Length: line.Length,
          DimForce: line.DimForce,
          MoorlogNo: line.MoorlogNo,
          IsAccident: line.IsAccident,
          StartBuoyId: line.StartBuoyId,
          EndBuoyId: line.EndBuoyId,
          AnalysisNo: line.AnalysisNo,
          SplicedLength: line.SplicedLength,
          MinMblAnchoring: line.MinMblAnchoring,
          MinMblRopesStraps: line.MinMblRopesStraps,
          MinMblChainShackleConnectionPlates: line.MinMblChainShackleConnectionPlates,
          //
          Components: line.Components,
        }) satisfies UpdateMooringGridLineDto
    );

    await this.mooringService.updateGridLines(this.mooringId, dto);
    this.toaster.showUpdated();
    this.pubsub.publish('list-entity:updated', { name: MooringComponentType.gridLine });
    if (canNavigateOnSave) {
      this.pubsub.publish('tabs:change-guard-ok', null);
    } else {
      await this.getGridLines();
    }
  }

  protected detached() {
    this.pubsub.unsub();
  }
}
